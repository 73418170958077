import React from 'react'
import HeadingText from './headingText'

const Headline = () => {
  return (
    <div className='p-10 bg-darkColor'
    >
  <div
   className='bg-bluebackground flex flex-col rounded-2xl bg-cover  w-full justify-center items-center p-12'
  >
    <h1 className='text-[25px] md:text-[30px] font-bold text-white font-sans text-center'>Let's Discuss Your Project</h1>
    <h3 className=' font-light text-white font-sans text-center'>Get free consultation and let us know your project idea to turn it into an amazing digital product</h3>



  </div>


    </div>
  )
}

export default Headline