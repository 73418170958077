import React from 'react'

const Textfield = (props) => {
  return (
    <div className='flex-col'>
      <h1 className=' text-white text-[20px] mb-3 mt-1 font-semibold'>{props.title}</h1>
      <input className=" text-black w-full p-2 px-4 rounded-sm font-serif placeholder-black" placeholder='Type here' type={props.type} name={props.name} required/>

    </div>
  )
}

export default Textfield