
import './App.css';
import Nav from './components/Nav'
import { Route,Routes ,
  BrowserRouter as Router } from "react-router-dom";
import Home from './pages/home';
import About from "./pages/about"
import Services from "./pages/services"
import Contact from "./pages/contact"
import Work from "./pages/work"
import Footer from './components/footer';



function App() {

 
  return (

<div className=' min-h-screen bg-contain ' >

<Router>
  <div className='bg-background flex flex-col '>
  <Nav />

<Routes>
<Route path="/"  element={<Home/>}/>
<Route path="/about"  element={<About/>}/>
<Route path="/services"  element={<Services/>}/>
<Route path="/contactus"  element={<Contact/>}/>
{/* <Route path="/work"  element={<Work/>}/> */}
{/* <Route path="/about"  element={<About/>}/> */}
</Routes>

  </div>
<Footer/>
      
</Router>

 </div>

  );
}

export default App;
