import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Textfield from '../components/textfield';
import HeadingText from '../components/headingText';
import SubHeading from '../components/subheading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jw8uvjc', 'template_0vuhize', form.current, '4H5SK0NmSmTwTUw6v')
      .then((result) => {
          console.log(result.text);
          toast.success("Message sent successfully")
          form.current.reset();
      }, (error) => {
        toast.warning(error.text);
          console.log(error.text);
      });
  };

  return (
 <div className=' px-10 pt-24 bg-darkColor'>
 <div className='pt-10 pb-5'>  
  <HeadingText text='Feel free to contact us.'/>
  <SubHeading text="Feel welcome to reach out anytime; we're here to assist you."/>
  </div>
      <form className="flex-col p-10 bg-darkColor" ref={form} onSubmit={sendEmail}>
      <Textfield title="Full Name :" type='text' name='to_name'/>
      <Textfield title="Email :" type='email' name='from_name'/>
      <h1 className='text-white text-[20px] mb-3 mt-1 font-semibold'>Message :</h1>
      <div className=' w-full h-24 '><textarea  className=" text-darkcolor w-full h-24 px-4 py-2 rounded-sm placeholder-black font-serif" placeholder='Budget, Scope, Timeline, Requirements...' name="message" required/></div>
      <button className=" bg-primary  px-6 py-2 text-white font-semibold text-[22px] rounded-2xl my-4 font-serif hover:bg-backgroundColor" type="submit" value="Send" > Submit</button>
    
  </form>
  <ToastContainer/>
 </div>
  )
}

export default Contact