import React from 'react'
import Header from '../components/header'
import Services from './services'
import About from './about'
import Contact from './contact'
import Headline from '../components/headline'


const Home = () => {
  return (
    <div className="via-purple-500 to-pink-500 text-white w-full "> 
   <Header/>
   <Services/>
  <Headline/>
   <About/>
   {/* <Contact/>  */}
    </div> )
}

export default Home