import React from 'react'
import HeadingText from '../components/headingText'
import { Servicess } from '../data/data'
import SubHeading from '../components/subheading'
import { fadeIn } from "../data/variants";
import { motion } from "framer-motion";


const Services = () => {
  return (
    <div className='flex flex-col px-5 pt-20 bg-darkColor'>
      <HeadingText text='Our Services'/>
      <div className='h-6'/>
      <SubHeading text="We're your go-to software company, offering mobile, web, and backend development, along with SAP services. From crafting stunning mobile apps to creating robust backend solutions, we're here to enhance your digital presence. Our SAP services drive business efficiency and innovation, empowering you to stay competitive in the digital era."/>
     

      <motion.div 
      // variants={fadeIn("right", 0.2)}
      // initial="hidden"
      // whileInView={"show"}
      // viewport={{once: false, amount: 0.7}}

      className="grid p-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
      {Servicess.map((service, index) => (
        <motion.div
        // variants={fadeIn("right", 0.2)}
        // initial="hidden"
        // whileInView={"show"}
        // viewport={{once: false, amount: 0.7}}
        
        className=" bg-darkColor hover:bg-darkshade rounded-3xl p-8 shadow-md border-darkshade border-[0.5px] hover:border-0" key={index}>
          <div className="text-center">
            <div className="text-4xl text-white mb-2"><service.icon/></div>
            <h2 className="text-xl text-white text-start font-semibold">{service.title}</h2>
          </div>
          <p className="mt-4 text-gray-600">{service.subtitle}</p>
        </motion.div>
      ))}
    </motion.div >


  

    </div>
  )
}

export default Services