import { CgWebsite } from 'react-icons/cg';
import {TbDeviceMobileCode} from 'react-icons/tb';
import {IoServerOutline} from 'react-icons/io5'
import {SiSap} from 'react-icons/si'
import {BsWindowFullscreen} from 'react-icons/bs'

export const Servicess=[
    {
        'title' : "Web App Development",
        'subtitle' : "Design and develop web applications that align perfectly with your business objectives.",
        'icon' : CgWebsite
    },
    {
        'title' : "Mobile App Development",
        'subtitle' : "Crafting Mobile Apps for Your Vision – Seamless, Stunning, Success.",
        'icon' : TbDeviceMobileCode
    },
    {
        'title' : "Backend Development",
        'subtitle' : "Empowering Your Business with Robust Backend Solutions.",
        'icon' : IoServerOutline
    },
    {
        'title' : "Full Stack Development",
        'subtitle' : "Full Stack Development: Bridging Frontend and Backend Excellence.",
        'icon' : BsWindowFullscreen
    },
    {
        'title' : "SAP",
        'subtitle' : "SAP Services: Elevating Business Efficiency and Innovation.",
        'icon' : SiSap
    }
];