import React from 'react'
import logo from "../asset/images/logo_light.png"
import SubHeading from './subheading'
import {LuMail} from 'react-icons/lu'
import {HiMiniDevicePhoneMobile} from 'react-icons/hi2'



const Footer = () => {
  return (
    <div className=' bg-darkcolor flex flex-col  '>
        <div className='flex md:flex-1 flex-col px-5 py-4'>
        <img src={logo} className=' h-16 w-28' ></img>
        <h1 className=' text-white text-[14px]'>
        Softkel is a leading software development and managed operations company dedicated to crafting top-notch web and mobile applications. We pride ourselves on delivering high-quality solutions that are not only cost-effective but also innovative and efficient. With a team of seasoned professionals, we bring years of experience and expertise to the table, ensuring your projects are developed with the latest technologies and industry best practices Our client-centric approach means we work closely with you to tailor solutions that precisely align with your business objectives. We're committed to delivering results faster, allowing you to stay ahead of the competition. Beyond development, we offer managed operations services to keep your applications running seamlessly. At Softkel, we're not just your developers; we're your innovation partners. Join us in our mission to make your digital dreams a reality.        </h1>
            
        </div>
        <div className=' flex-1 px-5 py-5'>
          <SubHeading text="Contact us :"/>
          <h1 className=' text-white text-[14px]' >Contact Us Today, Let's Connect, Discuss, and Fulfill Your Needs with Excellence and Care. Your Inquiry Matters to Us.</h1>
          <div className='flex flex-row pt-5  items-center'>
            <span className='p-2 items-center bg-primary rounded-xl'><LuMail className='text-white text-[20px] '></LuMail></span>
            <h1 className=' text-white text-[14px] px-2'>info@softkel.com</h1>

          </div>
          <div className='flex flex-row pt-5  items-center'>
            <span className='p-2 items-center bg-primary rounded-xl'><HiMiniDevicePhoneMobile className='text-white text-[20px] '></HiMiniDevicePhoneMobile></span>
            <h1 className=' text-white text-[14px] px-2'>+923244679138</h1>

          </div>

        </div>

    </div>
  )
}

export default Footer