import React from 'react'
import HeadingText from '../components/headingText'
import SubHeading from '../components/subheading'

const About = () => {
  return (
    <div className='flex flex-col px-10 pt-20 pb-10 bg-darkColor' >

<HeadingText text='About us'/>
<div className='h-8'/>
<SubHeading text="- We are a progressive software company with a fervent commitment to transforming ideas into tangible digital solutions. Our versatile expertise encompasses mobile app development, web app development, backend solutions, and SAP services. We exist to empower businesses, helping them navigate the digital landscape effectively."/>
 <SubHeading text= "- Our team is a dedicated assembly of professionals who breathe innovation. We are not just software developers; we are problem solvers, creators, and visionaries. Our mission is to provide our clients with the tools they need to succeed in an ever-evolving digital world."/>  
  <SubHeading text="- In mobile app development, we craft seamless and stunning applications that embody your vision. For web app development, we design feature-rich, visually appealing, and highly functional web applications that enhance your online presence and customer engagement. In the realm of backend development, we fortify the backbone of your digital ecosystem, ensuring secure data management and efficient communication."/> 
  <SubHeading text="- Our SAP services elevate business efficiency and innovation, enabling you to remain competitive. With a blend of cutting-edge technology and a customer-centric approach, we bring your digital dreams to life. Join us in this journey towards digital excellence and success."/> 
 
    </div>
  )
}

export default About